import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import SEO from '../components/Seo/Seo';
import logoImg from '../images/logo.png';
import logoImg2x from '../images/logo@2x.png';

const Wrapper = styled(Flex)(
  css({
    maxWidth: '860px',
    mx: 'auto',
  }),
);

const LogoWrapper = styled.div(
  css({
    mb: 10,
  }),
);

const List = styled.ul(
  css({
    listStyleType: 'disc',
    m: 0,
    p: 0,
    pl: '18px',
  }),
);
const ListItem = styled.li(
  css({
    color: 'text',
    fontFamily: 'body',
    fontSize: 'sm',
    pb: 4,
  }),
);

const IndexPage = () => (
  <>
    <SEO title="Agreement between User and American Samoa Department of Health (ASDOH)" />
    <Wrapper>
      <Flex.Box flexGrow="1">
        <LogoWrapper>
          <Link href="/">
            <Image
              src={logoImg}
              srcSet={`${logoImg} 1x, ${logoImg2x} 2x`}
              alt="American Samoa Departament of Health"
              responsive
            />
          </Link>
        </LogoWrapper>
        <Typography variant="h1" mb={5}>
          Agreement between User and American Samoa Department of Health (ASDOH)
        </Typography>
        <Typography variant="h2">
          Introduction
        </Typography>
        <Typography paragraph>
          This Traveler's Health Declaration User Agreement (the "Agreement") is made between the individual traveler (the "User") and The American Samoa Department of Health ("we", "us", or "our"). By completing and submitting the Traveler's Health Declaration Form (the "Declaration"), the User agrees to comply with the terms and conditions set forth in this Agreement.
        </Typography>
        <Typography variant="h2">
          1. Accuracy of Information
        </Typography>
        <Typography paragraph>
          1.1 The User agrees to provide accurate, truthful, and complete information in the Declaration.
        </Typography>
        <Typography paragraph>
          1.2 The User acknowledges that providing false, misleading, or incomplete information may result in denial of entry, quarantine, fines, or other legal consequences as determined by applicable laws and regulations.
        </Typography>
        <Typography variant="h2">
          2. Use of Information
        </Typography>
        <Typography paragraph>
          2.1 The information provided in the Declaration will be used solely for the purpose of assessing the User's health status and determining eligibility for follow-up or urgent health care.
        </Typography>
        <Typography paragraph>
          2.2 We may share the information provided in the Declaration with relevant public health authorities, government agencies, and airline carriers as required by law or for public health and safety purposes.
        </Typography>
        <Typography variant="h2">
          3. Data Protection and Privacy
        </Typography>
        <Typography paragraph>
          3.1 We are committed to protecting the User's personal data and privacy. All information provided in the Declaration will be handled in accordance with applicable data protection laws and our Privacy Policy.
        </Typography>
        <Typography paragraph>
          3.2 The User consents to the collection, use, and disclosure of their personal data as described in this Agreement and our Privacy Policy.
        </Typography>
        <Typography variant="h2">
          4. User Responsibilities
        </Typography>
        <Typography paragraph>
          4.1 The User agrees to monitor their health status and report any changes to the relevant authorities promptly.</Typography>
        <Typography paragraph>
          4.2 The User agrees to comply with all health and safety guidelines, quarantine requirements, and other regulations imposed by health authorities and destination countries.
        </Typography>
        <Typography variant="h2">
          5. Limitation of Liability
        </Typography>
        <Typography paragraph>
          5.1 We are not responsible for any loss, damage, or inconvenience resulting from the User's submission of false or inaccurate information.
        </Typography>
        <Typography paragraph>
          5.2 We are not liable for any decisions made by health authorities, government agencies, or airline carriers based on the information provided in the Declaration.
        </Typography>
        <Typography variant="h2">
          6. Amendments
        </Typography>
        <Typography paragraph>
          6.1 We reserve the right to amend this Agreement at any time. Any amendments will be communicated to the User through our official channels.
        </Typography>
        <Typography paragraph>
          6.2 The User's continued use of the Declaration constitutes acceptance of any amended terms and conditions.
        </Typography>
        <Typography variant="h2">
          7. Governing Law
        </Typography>
        <Typography paragraph>
          7.1 This Agreement shall be governed by and construed in accordance with the laws of the American Samoa Government.
        </Typography>
        <Typography paragraph>
          7.2 Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of the government of American Samoa.
        </Typography>
      </Flex.Box>
    </Wrapper>
  </>
);

export default IndexPage;
